<template>
  <div class="team wrap">
    <!-- Main body started -->
    
    
    <div class="sys_ad">
      <p>
        <router-link to="">
          <!-- <StaticImage v-if="locale==='en' || locale==='pb'" url="undraw.png" alt="" /> -->
          <StaticImage url="undraw.png" alt="" />
        </router-link>
      </p>
    </div>

    <div class="sys_ad team_ad">
      <p class="btnOrderNow" @click="clickEvent1">
        <router-link to="">
          {{ t("common.title.OrderNow") }}
        </router-link>
      </p>
    </div>
    <br />
    <div class="shadow-xs2 p-1">
      <div class="rob_one">
        <div class="one_box">
          <ol>
            <li>
              <i>{{ t("profile.label.walletBalance") }}</i>
              <span>{{ formatMoney(userDetail.amount) }}</span>
            </li>
            
            <li>
              <i>{{ t('common.title.todayIncome') }}</i>
              <span>{{ formatMoney(!ordersPlacedData?0:(ordersPlacedData?.theDayCommission)) }}</span>
            </li>
            <li>
              <i></i>
              <span class="s_ordersPlaced">
                <!-- {{ userDetail.orderCount }} -->
                <font><span class="sp01">{{ !ordersPlacedData?'0':(ordersPlacedData?.completedNumber) }}</span></font>
                <font><span class="sp01"> / </span></font>
                <font><span class="sp02">{{ !ordersPlacedData?'0':(ordersPlacedData?.notStartedNumber) }}</span></font>
              </span>
            </li>
            <!-- <li>
              <i>{{ t("profile.label.commission")+'%' }}</i>
              <span>
                {{  sysDataDictionaryWbObj.COMMISSION?.dataContent+'%' }}
              </span>
            </li> -->
          </ol>
        </div>
      </div>
    </div>
    <br />
    <div class="shadow-xs2 p-1 h200">
      <div id="con1" class="con1" ref="con1" :class="{anim:animate==true}" @mouseenter="mEnter" @mouseleave="mLeave">
        <p v-for='(item, index) in scorllList' :key="index">
          <span class="w40">{{item.nickname}}</span>
          <span class="w30">{{item.keyword}}</span>
          <span class="w30 redtxt">{{formatMoney(item.amount)}}</span>
        </p>
      </div>
    </div>
    <br />

    <!-- <div class="mt-1 text" v-if="locale === 'en'">
      <ul class="shadow-xs p-1 leading-3">
        <h4 class="my-1 text-center text-xs text-primary">order description</h4>
        <li><strong>1.</strong>each account can be combined every day <span class="text-primary"> 99</span> Order </li>
        <li><strong>2.</strong>The commission for placing an order is evenly based on the order amount <span class="text-primary">20%</span></li>
        <li><strong>3.</strong>The system is based on LBS technology and automatically combines the products through the cloud.</li>
        <li><strong>4.</strong>To avoid platform supervision, if the order is not confirmed and shipped within <span class="text-primary"> 24 </span> hours, the system will freeze the order amount.</li>
        <li><strong>5.</strong>When the platform matches the user's order, the platform sends the order information to the merchant's backend. If the user does not submit an order within <span class="text-primary"> 24 </span> hours, the order quantity will be frozen by the system. To bypass the supervision system, users' accounts will be scored, which directly affects the time and reputation of the user's next match! If there are other needs to be met, click to stop the order in time to avoid freezing over time.</li>
        <li><strong>6.</strong>If you have uncompleted orders or tasks based on LBS technology, there will be disputes with the merchant and it will not be possible to make withdrawals. Confirm that all tasks have been completed before checking out.</li>
      </ul>
    </div>
    <div class="mt-1 text" v-if="locale === 'pb'">
      <ul class="shadow-xs p-1 leading-3">
        <h4 class="my-1 text-center text-xs text-primary">Instruções de ordem</h4>
        <li><strong>1.</strong>A Comissão de Encomendas é calculada em <span class="text-primary">20%</span> do montante da encomenda, em média. </li>
        <li><strong>2.</strong>O sistema é baseado na tecnologia LBS e combina automaticamente os produtos através da nuvem.</li>
        <li><strong>3.</strong>Para evitar o monitoramento pela plataforma, se uma ordem não for confirmada para ser enviada dentro de <span class="text-primary">12 horas</span>, a quantia da ordem será congelada pelo sistema.</li>
        <li><strong>4.</strong>Quando a plataforma corresponde a uma ordem do usuário, a plataforma envia a informação da ordem para o back office do comerciante. Se o usuário não apresentar a ordem dentro de <span class="text-primary">12 horas</span>, o sistema congelará o valor da ordem. A fim de contornar o sistema de monitoramento, a conta do usuário será pontuada, o que afeta diretamente o tempo e a reputação do próximo jogo do usuário.</li>
        <li><strong>5.</strong>Se o senhor tiver ordens ou tarefas inacabadas baseadas na tecnologia LBS, haverá uma disputa com o comerciante e nenhuma retirada será possível. Queira certificar-se de que todas as tarefas tenham sido concluídas antes de fazer um pagamento.</li>
      </ul>
    </div>
    <div class="mt-1 text" v-if="locale === 'es'">
      <ul class="shadow-xs p-1 leading-3">
        <h4 class="my-1 text-center text-xs text-primary">Instrucciones de pedido</h4>
        <li><strong>1.</strong>La comisión de los pedidos se calcula en un <span class="text-primary">20%</span> del importe del pedido por término medio. </li>
        <li><strong>2.</strong>El sistema se basa en la tecnología LBS y empareja automáticamente los productos a través de la nube.</li>
        <li><strong>3.</strong>Para evitar el seguimiento por parte de la plataforma, si el pedido no se completa en <span class="text-primary">12</span> horas, el importe del pedido será congelado por el sistema.</li>
        <li><strong>4.</strong>Cuando la plataforma coincide con el pedido de un usuario, la plataforma envía la información del pedido al back office del comerciante. Si el usuario no completa el pedido en <span class="text-primary">12</span> horas, el sistema congelará el importe del mismo. Para eludir el sistema de vigilancia, se asigna una puntuación a la cuenta del usuario, que afecta directamente al tiempo y a la reputación de la participación del usuario en el siguiente pedido.</li>
        <li><strong>5.</strong>Si tiene pedidos o tareas sin terminar basados en la tecnología LBS, habrá una disputa con el comerciante y no será posible la retirada. Por favor, asegúrese de que todas las tareas se han completado antes de hacer un pago.</li>
      </ul>
    </div>
    <div class="mt-1 text" v-if="locale === 'ind'">
      <ul class="shadow-xs p-1 leading-3">
        <h4 class="my-1 text-center text-xs text-primary">deskripsi pesanan</h4>
        <li><strong>1.</strong>setiap akun dapat digabungkan setiap hari <span class="text-primary">99</span> Order. </li>
        <li><strong>2.</strong>Komisi untuk menempatkan pesanan secara merata berdasarkan jumlah pesanan <span class="text-primary">20%</span></li>
        <li><strong>3.</strong>Sistem ini didasarkan pada teknologi LBS dan secara otomatis menggabungkan produk melalui cloud.</li>
        <li><strong>4.</strong>Untuk menghindari pengawasan platform, jika pesanan tidak dikonfirmasi dan dikirim dalam waktu <span class="text-primary">24 jam</span>, sistem akan membekukan jumlah pesanan.</li>
        <li><strong>5.</strong>Ketika platform cocok dengan pesanan pengguna, platform mengirimkan informasi pesanan ke backend merchant. Jika pengguna tidak mengirimkan pesanan dalam waktu <span class="text-primary">12 jam</span>, jumlah pesanan akan dibekukan oleh sistem. Untuk melewati sistem pengawasan, akun pengguna akan dinilai, yang secara langsung memengaruhi waktu dan reputasi pertandingan pengguna berikutnya! Jika ada kebutuhan lain yang harus dipenuhi, klik untuk menghentikan pesanan tepat waktu untuk menghindari pembekuan seiring waktu.</li>
        <li><strong>5.</strong>Jika Anda memiliki pesanan atau tugas yang belum diselesaikan berdasarkan teknologi LBS, akan ada perselisihan dengan pedagang dan penarikan tidak dapat dilakukan. Konfirmasikan bahwa semua tugas telah diselesaikan sebelum check out.</li>
      </ul>
    </div>
    <div class="mt-1 text" v-if="locale === 'tur'">
      <ul class="shadow-xs p-1 leading-3">
        <h4 class="my-1 text-center text-xs text-primary">sipariş açıklaması</h4>
        <li><strong>1.</strong>her hesap günlük <span class="text-primary">99</span> Siparişle birleştirilebilir. </li>
        <li><strong>2.</strong>Sipariş miktarına göre eşit olarak sipariş verme komisyonu <span class="text-primary">20%</span></li>
        <li><strong>3.</strong>Bu sistem LBS teknolojisine dayalıdır ve ürünleri bulut aracılığıyla otomatik olarak birleştirir.</li>
        <li><strong>4.</strong>Platform izlemesini önlemek için sipariş onaylanmazsa ve <span class="text-primary">24 saat</span> içinde kargoya verilmezse sistem sipariş miktarını dondurur .</li>
        <li><strong>5.</strong>Platform bir kullanıcının siparişiyle eşleştiğinde, platform sipariş bilgilerini satıcının arka ucuna gönderir. Kullanıcı siparişi <span class="text-primary">24 saat</span> içinde göndermezse, sipariş miktarı sistem tarafından dondurulur. Gözetim sistemini atlamak için, kullanıcının hesabı değerlendirilecek ve bu, kullanıcının bir sonraki maçının zamanını ve itibarını doğrudan etkileyecek! Karşılanması gereken başka gereksinimler varsa, zamanla donmayı önlemek için siparişi zamanında durdurmak için tıklayın.</li>
        <li><strong>5.</strong>LBS teknolojisine dayalı tamamlanmamış siparişleriniz veya görevleriniz varsa, satıcıyla bir anlaşmazlık olacaktır ve para çekme işlemi yapılamaz. Çıkış yapmadan önce tüm görevlerin tamamlandığını onaylayın.</li>
      </ul>
    </div> -->
    <!-- <div class="sys_ad task_ad">
      <p>
        <router-link to="/grabOrderList2">
          {{ t("common.title.Task") }}
        </router-link>
      </p>
    </div> -->
  </div>
  <Loading :loading="loading" />

  <AModal
    v-model:visible="successDialog2"
    centered
    :title="null"
    :footer="null"
    wrapClassName="Sys_Popup2"
    width="85%"
  >
    <div class="icon">
      <p class="loading3"></p>
    </div>
    <p
      class="p_text"
      style="text-align: center"
      v-html="t('common.title.orserSuccess')"
    ></p>
  </AModal>

  <AModal
    v-model:visible="successDialog"
    centered
    :title="null"
    :footer="null"
    wrapClassName="Sys_Popup2"
    width="85%"
  >
    <div class="icon">
      <p class="loading2"></p>
    </div>
    <p
      class="p_text"
      style="text-align: center"
      v-html="t('common.title.orderMatching')"
    ></p>
  </AModal>
</template>

<script>
import { onMounted, ref } from "vue";
import { formatMoney } from "@/utils/formatter";
import StaticImage from "@/components/StaticImage.vue";
import { useI18n } from "@/lang";
import api from "@/api";
import { userDetail } from "@/hooks/userDetail";
import Loading from "@/components/Loading.vue";
import { message } from "ant-design-vue";
// import { useRouter } from "vue-router";
import { sysDataDictionaryWbObj } from '@/hooks/sysDataDictionaryWbObj'

export default {
  name: "GrabOrder",
  components: { Loading, StaticImage },
  setup(prop, context) {
    console.log(prop, context);
    // const router = useRouter();
    const { t, locale } = useI18n();
    let scorllList = ref(null);
    let ordersPlacedData = ref(null);
    let animate = ref(true);
    let loading = ref(false);
    const successDialog = ref(false);
    const successDialog2 = ref(false);
    api.getUserDetails();
    onMounted(() => {
      api.webOrderTaskRecords().then((res) => {
        if (res?.data?.success) {
          scorllList.value = res.data.data;
          // console.log(scorllList.value,'.....11')
        }
      });

      getOrder()

      
    });

    function getOrder(){
      api.getOrdersPlaced().then((res) => {
        if (res?.data?.success) {
          ordersPlacedData.value = res.data.data;
          // console.log(ordersPlacedData.value.completedNumber,'.....11')
        }
      });
    }

    function clickEvent1() {
      try {
        setTimeout(() => {
          loading.value = true;
        }, 50);
        // console.log(loading);
        api.createOrder().then((res) => {
          loading.value = false;
          if (res?.data?.success) {
            message.success(t("common.message.withdrawSuccess"));
          }
          getOrder()
          // else {
          //   if (res?.data?.message === "order_detail_3") return;
          //   if (res?.data?.message === "user_buy_record_save_7"){
          //     successDialog.value = true;
          //     return
          //   }
          //   router.replace("/grabOrderList2");
          // }
        });
      } finally {
        loading.value = false;
      }
    }

    function mEnter(){

    }
    function mLeave(){

    }

    return {
      formatMoney,
      t,
      locale,
      userDetail,
      clickEvent1,
      loading,
      successDialog,
      successDialog2,
      sysDataDictionaryWbObj,
      scorllList,
      ordersPlacedData,
      mEnter,
      mLeave,
      animate
    };
  },
};
</script>
<style lang="scss" scoped>
.team{
  overflow-y:scroll
}
.btnOrderNow {
  background-color: #e23d6d;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  // width:80%;
}
.btnOrderNow a {
  color: #fff;
}
.p-1 {
    padding: 5px;
}

.mt-1{
  background: #fff;
  padding:5px 10px 50px 10px;
}
.mt-1 li{
  line-height: 30px;
  display: block;
}
.text-primary{
  color:#e23d6d;
}
.my-1 {
  text-align: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.shadow-xs2{
  width:92%;
  margin: 0 auto;
  box-shadow: 0 2px 5px 0 #dedede;
  background-color: #fff;
  border-radius: 5px;
}
.shadow-xs2 .one_box i{
  color:#333
}
.shadow-xs2 .one_box span{
  color:#e23d6d;
}
.rob_one .one_box ol li{
  width:45%;
  text-align: center;
}
.h200{
  height: 260px;
  overflow:hidden;
}
.anim{
  // transition: all .8s;
  animation: myMove 20s linear infinite;
  animation-fill-mode: forwards;
}
 /*文字无缝滚动*/
@keyframes myMove {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-830px);
  }
}
.con1 p{
  padding: 5px 0;
}
.w30{
  width:30%;
  display: inline-block;
  text-align: right;
}
.w40{
  width:40%;
  display: inline-block;
  text-align: left;
}
.redtxt{
  color:#e23d6d;
}
.s_ordersPlaced{
  font-weight: bold;
  margin-left: 90%;
  padding:  5px;
  text-align: center;
  width: 100%;
  font{
    float: left;
  }
  span{
    font-size: 18px !important;
    padding:  5px;
  }
  .sp02{
    color:white !important;
    background: limegreen;
    // display: inline-block;
  }
}
</style>
