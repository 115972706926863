<template>
  <div v-if="userTeamTotal && userDetail" class="team">
    <!-- Main body started -->
    <div class="one">
      <div class="title">
        {{ t("common.title.team") }}
        <p>{{ t("common.title.teamDesc") }}</p>
      </div>
      <div class="one_box">
        <ul>
          <h2>
            <StaticImage
              :url="'Head/' + userDetail.avatarAddress + '.png'"
            ></StaticImage>
          </h2>
          <h3>
            <span>{{ userDetail.userNick }}</span>
            <VipLevel :level="userDetail.levelName?.slice(3)" />
          </h3>
          <!-- <li>{{ t('server.avtar_title') }}</li> -->
        </ul>
        <ol>
          <li>
            <p>
              <b>{{ userTeamTotal.teamMemberTotal }}</b>
              {{ t("team.label.totalMembers") }}
            </p>
          </li>
          <li>
            <p>
              <b>{{ formatMoney(userTeamTotal.teamCommissionTotal) }}</b>
              {{ t("team.label.totalCommission") }}
            </p>
          </li>
          <!-- <li>
            <p>
              <b>{{ formatMoney(userDetail.subRebate) }}</b>
              {{ t("team.label.samedaySubcCmmission") }}
            </p>
          </li> -->
        </ol>
      </div>
    </div>
    <!-- <div class="sys_ad team_ad">
      <p>
        <router-link to="/team_activity">
          <StaticImage v-if="locale==='en'" url="site_22.png" alt="" />
          <StaticImage v-if="locale==='pb'" url="site_22_pb.png" alt="" />
          <StaticImage v-if="locale==='es'" url="site_22_es.jpg" alt="" />
          <StaticImage v-if="locale==='ind'" url="site_22_ind.jpg" alt="" />
          <StaticImage v-if="locale==='tur'" url="site_22_tur.jpg" alt="" />
        </router-link>
      </p>
    </div> -->
    <div class="two">
      <div class="two_list">
        <template v-for="(item, index) in teamMemberList" :key="index">
          <router-link :to="{ name: 'TeamDetail', params: { level: +item.teamMemberLevel?.slice(3) } }">
            <ul>
              <h1><StaticImage url="site_41.png" alt="" /></h1>
              <h2>{{'Level' +item.teamMemberLevel?.slice(3) }}</h2>
              <h3>
                {{ t("team.label.commission") }}:
                <b>{{ formatMoney(item.teamCommissionTotal) }}</b>
                {{ t("team.label.members") }}:
                <b>{{ item.teamMemberTotal }}</b>
              </h3>
              <li><i class="iconfont icon-you2"></i></li>
            </ul>
          </router-link>
        </template>
      </div>
    </div>
    <!-- End of the body -->
  </div>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import { formatMoney } from "@/utils/formatter";
import StaticImage from "@/components/StaticImage.vue";
import { useI18n } from "@/lang";
import VipLevel from "@/components/VipLevel.vue";
import api from "@/api";
import { userDetail } from "@/hooks/userDetail";
export default {
  name: "Team",
  components: { StaticImage, VipLevel },
  setup() {
    const { t,locale } = useI18n();
    let userTeam = ref();
    let userTeamTotal = ref(null);
    let teamMemberList = ref(null);
    userTeam = inject("userTeam");
    onMounted(() => {
      api.teamReport().then((res) => {
        if (res?.data?.success) {
          userTeamTotal.value = res.data.data;
        }
      });
      api.teamMemberCount().then((res) => {
        if (res?.data?.success) {
          teamMemberList.value = res.data.data;
        }
      });
    });

    return {
      userTeam,
      formatMoney,
      t,
      locale,
      userTeamTotal,
      userDetail,
      teamMemberList,
    };
  },
};
</script>
